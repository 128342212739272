@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.custom-scroll {
  overflow: auto;
}

.custom-scroll::-webkit-scrollbar {
  /* width: 2px; */
  background-color:white;
  /* background-color: transparent; */
  display: none;
}
.sticky-tabs {
  position: -webkit-sticky;
  position: sticky;
  top: 58px;
  z-index: 40;
  transition: all 0.3s ease;
}
@media (min-width: 768px) {
  .sticky-tabs {
    top: 80px;
  }
}

a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none !important;
}

/* Branding on the Privacy trigger */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none !important;
}
/* .custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-track-piece {
  background-color: white;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #4B5090; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */